<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
            color="primary"
            indeterminate
            size="50"
        ></v-progress-circular>
      </v-overlay>

      <div class="main">

        <v-card class="shadow">
          <v-card-text class="pa-0">
            <v-row no-gutters>

              <v-col cols="12" lg="3" style="border-right: 1px solid #eaeaea">
                <Menu v-if="can('supplier')"/>
                <StoreCard v-if="can('store')"/>
              </v-col>

              <v-col cols="12" lg="9">
                <div class="pa-2">
                  <StatisticCards ref="statisticCards"
                                  :loading="isLoading"
                                  :statistics="statistics"
                                  :store-id="storeId"
                                  class="mb-3"
                                  @is-today="[isToday = true,getPartnerVouchers(),$vuetify.goTo('#table')]"/>
                </div>

                <div class="d-lg-flex d-block justify-space-between pa-3">


                  <div>
                    <v-btn :to="'/partner-vouchers/store/'+storeId+'/scan-history'"
                           class="mr-2"
                           color="gifty"
                           dark
                           text>
                      <v-icon left>mdi-history</v-icon>
                      Historique des scans
                      <v-icon right>mdi-arrow-right</v-icon>
                    </v-btn>

                    <v-btn :to="'/partner-vouchers/store/'+storeId+'/payment-history'"
                           class="mr-2"
                           color="gifty"
                           dark
                           text>
                      <v-icon left>mdi-history</v-icon>
                      Historique des paiements
                      <v-icon right>mdi-arrow-right</v-icon>
                    </v-btn>
                  </div>

                  <div>
                    <v-btn color="gifty"
                           class="mr-2"
                           dark
                           @click="$refs.addPaymentDialog.open()"
                           text>
                      <v-icon left>mdi-plus</v-icon>
                      Paiement
                    </v-btn>

                    <v-btn :to="'/partner-vouchers/store/'+storeId+'/scan'"
                           color="gifty"
                           dark
                           depressed>
                      <v-icon left>mdi-data-matrix-scan</v-icon>
                      Scanner
                    </v-btn>
                  </div>
                </div>

                <v-divider/>

                <v-card id="table" flat>

                  <v-card-title>
                    <div>
                      <v-text-field
                          v-model="keyword"
                          append-icon="mdi-magnify"
                          clearable
                          dense
                          filled
                          hide-details
                          placeholder="Recherche..."
                          rounded
                          single-line
                      ></v-text-field>
                    </div>

                    <v-spacer/>

                    <v-btn color="grey"
                           text
                           @click="$refs.filterDialog.open()">
                      <v-icon left>mdi-filter-outline</v-icon>
                      Filtre
                    </v-btn>

                    <v-btn :class="isToday ? 'v-btn--active' : ''"
                           :color="isToday ? 'primary' : 'grey'"
                           text
                           @click="[isToday = !isToday,getPartnerVouchers()]">
                      <v-icon left>mdi-clock-check-outline</v-icon>
                      Aujourd'hui
                    </v-btn>

                    <v-btn color="grey"
                           icon
                           @click="[dates=[],getPartnerVouchers()]">
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>

                  </v-card-title>

                  <v-divider/>

                  <v-card-text v-if="isLoading">
                    <v-skeleton-loader type="table"/>
                  </v-card-text>

                  <div v-else>

                    <v-card-text class="pa-0">

                      <v-simple-table v-if="vouchers.length">
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th>Montant</th>
                            <th>Service</th>
                            <th>Fournisseur</th>
                            <th>Téléphone</th>
                            <th>Code du voucher</th>
                            <th>Scanner le</th>
                            <th class="text-center" v-if="can('supplier')">Rembourser</th>
                            <th>Imprimer</th>
                          </tr>
                          </thead>
                          <tbody>

                          <tr v-for="(item,i) in vouchers"
                              :key="i">

                            <td class="gifty--text font-weight-medium">
                              {{ item.voucher ? CurrencyFormatting(item.voucher.amount) : 'Remboursé' }}
                            </td>

                            <td class="font-weight-medium">
                              {{ item.voucher && item.voucher.service_name ? item.voucher.service_name : '-' }}
                            </td>

                            <td>{{ item.supplier.name }}</td>

                            <td>{{ item.phone || '-' }}</td>

                            <td class="primary--text font-weight-medium">
                              {{ item.voucher?.code }}
                            </td>

                            <td>
                              {{ item.updated_at }}
                            </td>

                            <td class="text-center" v-if="can('supplier')">

                              <div v-if="!item.deleted_at">
                                <v-icon v-if="item.is_refunded" color="success">mdi-check-circle</v-icon>

                                <v-tooltip v-else bottom color="gifty">
                                  <template v-slot:activator="{ on }">
                                    <v-btn class="text-none"
                                           color="gifty"
                                           icon
                                           @click="refund(item.id)"
                                           v-on="on">
                                      <v-icon>mdi-backup-restore</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Rembourser</span>
                                </v-tooltip>
                              </div>

                              <div v-else>
                                <v-chip color="red" dark small>
                                  <v-icon dark left small>mdi-close</v-icon>
                                  Supprimé
                                </v-chip>
                              </div>

                            </td>

                            <td>
                              <v-tooltip bottom color="gifty">
                                <template v-slot:activator="{ on }">
                                  <v-btn :disabled="!!(item.deleted_at)"
                                         color="gifty"
                                         icon
                                         @click="[scan= item ,printPdf()]"
                                         v-on="on">
                                    <v-icon>mdi-cloud-print-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>Imprimer</span>
                              </v-tooltip>
                            </td>

                          </tr>

                          </tbody>
                        </template>
                      </v-simple-table>

                      <div v-else>
                        <NoResults @reload="getPartnerVouchers"/>
                      </div>

                    </v-card-text>

                    <v-divider v-if="total > 10"/>

                    <v-card-actions v-if="total > 10">

                      <div :style="{width: '120px'}">
                        <v-select v-model="perPage"
                                  :items="[10,20,30,50,100]"
                                  dense
                                  hide-details
                                  label="Ligne par page"
                                  outlined
                        ></v-select>
                      </div>

                      <v-spacer/>

                      <v-pagination v-model="pagination.current"
                                    :length="pagination.total"
                                    circle
                                    total-visible="8"
                                    @input="getPartnerVouchers"></v-pagination>

                    </v-card-actions>

                  </div>


                </v-card>

              </v-col>

            </v-row>
          </v-card-text>
        </v-card>

      </div>

      <FilterDialog ref="filterDialog" @filter="handleFilter"/>

      <div id="print" class="d-none">

        <div style="text-align: center;padding: 30px">
          <img :src="require('@/assets/logo2.png')" alt="logo">
          <h4 style="padding-top: 20px">
            Bon Gifty
          </h4>
        </div>

        <table class="table">
          <tr>
            <th>Service</th>
            <td>
              {{ scan.voucher && scan.voucher.service_name ? scan.voucher.service_name : '-' }}
            </td>
          </tr>
          <tr>
            <th>Fournisseur</th>
            <td>{{ scan.supplier ? scan.supplier.name : '-' }}</td>
          </tr>
          <tr>
            <th>Numéro de Téléphone</th>
            <td>{{ scan.phone || '-' }}</td>
          </tr>
          <tr>
            <th>Date de scanne</th>
            <td> {{ scan.updated_at }}</td>
          </tr>
          <tr style="font-size: 18px">
            <th>Voucher</th>
            <th>
              {{ scan.voucher && scan.voucher.amount ? CurrencyFormatting(scan.voucher.amount) + " DZD" : 'Remboursé' }}
            </th>
          </tr>
          <tr>
            <th></th>
            <td></td>
          </tr>
        </table>
      </div>

      <AddPaymentDialog ref="addPaymentDialog" @success-payment="vouchers.unshift($event)"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import FilterDialog from "./components/FilterDialog";
import StatisticCards from "./components/StatisticCards";
import Menu from "@/views/partner_vouchers/components/Menu";
import StoreCard from "@/views/partner_vouchers/components/StoreCard";
import AddPaymentDialog from "@/views/partner_vouchers/components/AddPaymentDialog";

export default {
  components: {
    AddPaymentDialog,
    Menu,
    StoreCard,
    NoResults,
    FilterDialog,
    StatisticCards
  },
  data() {
    return {
      isLoading: false,
      overlay: false,
      isToday: false,

      storeId: null,
      keyword: null,
      dates: [],
      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },

      vouchers: [],
      statistics: {},
      id: null,

      scan: {}
    };
  },
  methods: {
    getPartnerVouchers() {
      this.isLoading = true
      HTTP.get('/v1/partner-vouchers?page=' + this.pagination.current, {
        params: {
          isToday: this.isToday,
          storeId: this.storeId,
          perPage: this.perPage,
          dates: this.dates,
          keyword: this.keyword
        }
      }).then(res => {
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;

        this.vouchers = res.data.data.data

        this.statistics = res.data.statistics

        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    handleFilter(e) {
      this.dates = e.dates
      this.getPartnerVouchers()
    },

    refund(id) {
      this.id = id;
      this.$confirm_dialog_body = 'Êtes-vous sûr de vouloir REMBOURSER cet voucher';
      this.$confirm_dialog = true;
    },
    handleRefund() {
      this.overlay = true;
      HTTP.post("/v1/partner-vouchers/refund", {
        id: this.id,
      }).then(() => {
        this.$successMessage = "Cet voucher a été remboursé avec succès.";
        this.overlay = false;
        this.getPartnerVouchers()
        this.id = null;
      })
          .catch((err) => {
            this.overlay = false;
            console.log(err);
          });
    },

    printPdf() {
      const options = {
        name: '_blank',
        styles: [
          'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        ],
        autoClose: true,
        windowTitle: "Bon Gifty",
      }
      let _vm = this
      setTimeout(function () {
        _vm.$htmlToPaper('print', options);
      }, 500)

    },
  },
  watch: {
    perPage() {
      this.pagination.current = 1
      this.getPartnerVouchers()
    },
    keyword() {
      this.getPartnerVouchers()
    },
    '$route.params.id': function () {
      this.storeId = this.$route.params.id
      this.getPartnerVouchers()
    },
    $is_confirm: function (el) {
      if (el) {
        this.handleRefund();
        this.$is_confirm = false;
      }
    },
  },
  created() {
    this.storeId = this.$route.params.id
    this.getPartnerVouchers()
  },
};
</script>

<style scoped></style>
